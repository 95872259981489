import React from 'react';

const EraMenu = ({ onSelectEra, onBack }) => {
  const eras = ['Bármikor', '1950-es évek', '1960-as évek', '1970-es évek', '1980-as évek', '1990-es évek', '2000-es évek', '2010-es évek és újabbak'];

  return (
    <div className="menu">
      {eras.map(era => (
        <button key={era} onClick={() => onSelectEra(era)}>
          {era}
        </button>
      ))}
      <button onClick={onBack}>Back</button>
    </div>
  );
};

export default EraMenu;