import React, { useState } from 'react';
import MainMenu from './MainMenu';
import DifficultyMenu from './DifficultyMenu';
import EraMenu from './EraMenu';
import GameScreen from './GameScreen';

import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';

const App = () => {
  const [currentScreen, setCurrentScreen] = useState('MainMenu');
  const [gameSettings, setGameSettings] = useState({ type: '', difficulty: '', era: '' });

  const handleSelectGameType = (type) => {
    setGameSettings({ ...gameSettings, type });
    setCurrentScreen('DifficultyMenu');
  };

  const handleSelectDifficulty = (difficulty) => {
    setGameSettings({ ...gameSettings, difficulty });
    setCurrentScreen('EraMenu');
  };

  const handleSelectEra = (era) => {
    setGameSettings({ ...gameSettings, era });
    setCurrentScreen('GameScreen');
  };

  const handleBack = () => {
    const screenOrder = ['MainMenu', 'DifficultyMenu', 'EraMenu', 'GameScreen'];
    const currentIndex = screenOrder.indexOf(currentScreen);
    console.log(currentIndex)
    if (currentIndex > 0) {
      setCurrentScreen(screenOrder[currentIndex - 1]);
    }
  };

  const exitGame = () => {
    setCurrentScreen('MainMenu');
  };

  switch (currentScreen) {
    case 'MainMenu':
      return <MainMenu onSelectGameType={handleSelectGameType} onBack={handleBack} />;
    case 'DifficultyMenu':
      return <DifficultyMenu onSelectDifficulty={handleSelectDifficulty} onBack={handleBack} />;
    case 'EraMenu':
      return <EraMenu onSelectEra={handleSelectEra} onBack={handleBack} />;
    case 'GameScreen':
      return <GameScreen onBack={exitGame} gameSettings={gameSettings} />;
    default:
      return <div>Invalid screen</div>;
  }
};

export default App;