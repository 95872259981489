import React from 'react';

const MainMenu = ({ onSelectGameType, onBack }) => {
  const gameTypes = [
    'Kezdő', 'Haladó', '1 másodperc', '3 másodperc', '5 másodperc',
    'Hangszeres', 'Ének', 'Kezdő visszafelé', 'Haladó visszafelé'
  ];

  return (
    <div className="menu">
      {gameTypes.map(type => (
        <button key={type} onClick={() => onSelectGameType(type)}>
          {type}
        </button>
      ))}
    </div>
  );
};

export default MainMenu;