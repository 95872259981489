import React, { useEffect, useState } from 'react';

const all_songs = require('./songs.json');

const SimpleQuestion = ({ song, gameSettings, next, songs }) => {
    const [showSolution, setShowSolution] = useState(null);
    const [playCount, setPlayCount] = useState(0);
    const [choice, setChoice] = useState(null);
    const [options, setOptions] = useState(null);

    const reveal = () => {
        setShowSolution(song.id);
    };

    const replay = () => {
        setPlayCount(playCount + 1);
    };

    const choose = (id) => {
        if (!choice) {
            setChoice(id);
            if (id === song.id) {
                setTimeout(() => { next(true); setChoice(null); }, 3000);
            } else {
                setTimeout(() => { next(false); setChoice(null); }, 3000);
            }
        }
    };

    useEffect(() => {
        if (song) {
            if (showSolution === song.id) {
                const audio = new Audio('songs/' + song.id + '/solution.mp4');
                const p = audio.play();
                return () => { p.then(() => { audio.pause(); }); };
            }

            let file = 'solution';
            switch (gameSettings.type) {
                case 'Haladó':
                    file = 'sample';
                    break;
                case 'Hangszeres':
                    file = 'inst-3';
                    break;
                case 'Ének':
                    file = 'vocals';
                    break;
                case 'Kezdő visszafelé':
                    file = 'solution-reverse';
                    break;
                case 'Haladó visszafelé':
                    file = 'sample-reverse';
                    break;
            }
            console.log(file, gameSettings.type)
            const audio = new Audio('songs/' + song.id + '/'+file+'.mp4');
            const p = audio.play();
            
            switch (gameSettings.type) {
                case '1 másodperc':
                    p.then(() => setTimeout(() => { audio.pause(); }, 1000));
                    break;
                case '3 másodperc':
                    p.then(() => setTimeout(() => { audio.pause(); }, 3000));
                    break;
                case '5 másodperc':
                    p.then(() => setTimeout(() => { audio.pause(); }, 4000));
                    break;
            }

            return () => { p.then(() => { audio.pause(); }); }
        }
        return () => {};
    }, [song, gameSettings, playCount, showSolution]);

    useEffect(() => {
        if (gameSettings.difficulty === 'Négyből egy') {
            const opts = [];
            opts.push(song);
            while (opts.length < 4) {
                const randomIndex = Math.floor(Math.random() * songs.length);
                const randomSong = songs[randomIndex];
                if (!opts.includes(randomSong)) {
                    opts.push(randomSong);
                }
            }
            setOptions(opts.sort(() => Math.random() - 0.5));
        }
    }, [song, gameSettings, songs]);

    return (
        <div>
            { showSolution === song.id ? (
                <div>
                    <p className="me-3">{ song.artist } - <em>{ song.title }</em></p>
                    <div className="d-flex">
                        <button onClick={() => next(false)} className="btn btn-danger">Nem talált :(</button>
                        <button onClick={() => next(true)} className="btn btn-success">Talált! :)</button>
                    </div>
                </div>
            ) : (
                gameSettings.difficulty === 'Négyből egy' ? (
                    <div>
                        { options ? options.map(option => (
                            <button key={option.id} onClick={() => choose(option.id)} className={(
                                choice && option.id === song.id 
                                ? 'success' 
                                : choice && choice === option.id
                                ? 'fail'
                                : '')}>
                                { option.artist } - <em>{ option.title }</em>
                            </button>
                        )): undefined }

                        <button className="mt-5" onClick={replay}>Újra</button>
                    </div>
                ) : (
                    <div>
                        <button onClick={replay}>Újra</button>
                        <button onClick={reveal}>Mutasd a megoldást</button>
                    </div>
                )
            ) }
        </div>
    );
};

export default SimpleQuestion;