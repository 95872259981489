import React from 'react';

const DifficultyMenu = ({ onSelectDifficulty, onBack }) => {
  const difficulties = ['Négyből egy', 'Könnyű', 'Közepes', 'Nehéz', 'Fagyasztott csirkefarhát'];

  return (
    <div className="menu">
      {difficulties.map(difficulty => (
        <button key={difficulty} onClick={() => onSelectDifficulty(difficulty)}>
          {difficulty}
        </button>
      ))}
      <button onClick={onBack}>Vissza</button>
    </div>
  );
};

export default DifficultyMenu;