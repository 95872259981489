import React, { useEffect, useState } from 'react';
import SimpleQuestion from './SimpleQuestion';

const all_songs = require('./songs.json');

const GameScreen = ({ onBack, gameSettings }) => {
    const [exit, setExit] = useState(false);
    const [songs, setSongs] = useState([]);
    const [currentSong, setCurrentSong] = useState(0);
    const [score, setScore] = useState(0);
    const [finished, setFinished] = useState(false);
    
    

    useEffect(() => {
        const era = gameSettings.era.substring(2, 3);
        const filteredSongs = all_songs.filter(song => {
            if (gameSettings.era !== 'Bármikor') {
                if (('' + song.year).substring(2,3) !== era) {
                    return false;
                }
            }
            
            if (gameSettings.difficulty === 'Könnyű') {
                if (song.duration < 20 || song.best_rank > 1) {
                    return false;
                }
            } else if (gameSettings.difficulty === 'Közepes') {
                if (song.duration < 10 || song.best_rank === 1) {
                    return false;
                }
            } else if (gameSettings.difficulty === 'Nehéz') {
                if (song.duration > 10 || song.best_rank > 2) {
                    return false;
                }
            } else if (gameSettings.difficulty === 'Fagyasztott csirkefarhát') {
                if (song.duration > 10 || song.best_rank < 3) {
                    return false;
                }
            }
            return true;
        });
        // Choose 10 random songs
        let randomSongs = [];
        if (filteredSongs.length < 10) {
            randomSongs = filteredSongs;
        } else {
            while (randomSongs.length < 10) {
                const randomIndex = Math.floor(Math.random() * filteredSongs.length);
                const randomSong = filteredSongs[randomIndex];
                if (!randomSongs.includes(randomSong)) {
                    randomSongs.push(randomSong);
                }
            }
        }
        setSongs(randomSongs);
    }, [gameSettings]);

    const nextSong = (v) => {
        setScore(score + (v ? 1 : 0));
        if (currentSong < songs.length - 1) {
            setCurrentSong(currentSong + 1);
        }
        else {
            setFinished(true);
        }
    };

    if (exit) {
        return (
            <div className="game menu">
                <p>Tényleg ki akarsz lépni?</p>
                <div className="d-flex">
                    <button onClick={() => setExit(false)}>No</button>
                    <button onClick={onBack}>Yes</button>
                </div>
            </div>    
        )
    }

    if (!songs || !songs.length) {
        return (
            <div className="game menu">
                <p>Töltődik...</p>
                <button onClick={() => setExit(true)}>Exit</button>
            </div>
        );
    }

    if (finished) {
        return (
            <div className="game menu">
                <p>Gratulálok!</p>
                <p>Pontszám: {score}</p>
                <button onClick={onBack}>Kilépés</button>
            </div>
        );
    }

    return (
        <div className="game menu">
            <div className="d-flex justify-content-between">
                <p>Pontszám: {score}</p>
                <p>Feladvány: {currentSong + 1} / {songs.length}</p>
            </div>
            
            <SimpleQuestion song={songs[currentSong]} gameSettings={gameSettings} next={(v) => nextSong(v)} songs={songs} />

            <button className="mt-5" onClick={() => setExit(true)}>Kilépés</button>
        </div>
    );
};

export default GameScreen;